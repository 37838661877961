<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    header="Paciente Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <label for="nombre"
            ><strong>NOMBRE PACIENTE: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="nombre"
            v-model.trim="data_paciente.nombre"
            required="true"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="sexo"><strong>SELECCIONE SEXO: </strong></label>
          <Dropdown
            id="sexo"
            v-model="data_paciente.sexo"
            :options="sexo"
            optionLabel="label"
            placeholder="Seleccione un sexo..."
          >
          </Dropdown>
          <small class="p-invalid" v-if="errors.sexo">{{
            errors.sexo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="fecha_nacimiento"
            ><strong>EDAD: </strong
            ><strong class="p-invalid">{{ edadMesesCliente }}</strong></label
          >
          <InputText
            id="fecha_nacimiento"
            v-model="data_paciente.fecha_nacimiento"
            type="date"
            :max="fecha_actual"
          />
          <small class="p-invalid" v-if="errors.fecha_nacimiento">{{
            errors.fecha_nacimiento[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="ci_nit"><strong>CI/NIT: </strong></label>
          <InputText id="ci_nit" v-model="data_paciente.ci_nit" />
          <small class="p-invalid" v-if="errors.ci_nit">{{
            errors.ci_nit[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="telefono"><strong>TEL&Eacute;FONO: </strong></label>
          <InputText
            id="telefono"
            v-model="data_paciente.telefono"
            required="true"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
          <small class="p-invalid" v-if="errors.telefono">{{
            errors.telefono[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="correo"
            ><strong>CORREO ELECTR&Oacute;NICO: </strong>
          </label>
          <InputText
            id="correo"
            v-model="data_paciente.correo"
            required="true"
          />
          <small class="p-invalid" v-if="errors.correo">{{
            errors.correo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <label for="estado"><strong>ESTADO: </strong></label>
          <Dropdown
            id="estado"
            v-model="data_paciente.estado"
            :disabled="!data_paciente.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-4">
          <label for="domicilio"><strong>DOMICILIO: </strong> </label>
          <Textarea
            id="domicilio"
            v-model="data_paciente.domicilio"
            required="true"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.domicilio">{{
            errors.domicilio[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="observacion"
            ><strong>OBSERVACI&Oacute;N: </strong>
          </label>
          <Textarea
            id="observacion"
            v-model="data_paciente.observacion"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.observacion">{{
            errors.observacion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="contactos"><strong>CONTACTOS: </strong> </label>
          <Textarea
            id="contactos"
            v-model="data_paciente.contactos"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.contactos">{{
            errors.contactos[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="paciente.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="paciente.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-primary p-button-lg"
        @click="guardarPaciente"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
  
<script>
import PacienteService from "@/service/PacienteService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    paciente: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  pacienteService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_paciente: this.paciente,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
        { label: "OBITADO", value: 2 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      fecha_actual: new Date().toISOString().slice(0, 10),
      sexo: [
        { label: "MASCULINO", value: "MASCULINO" },
        { label: "FEMENINO", value: "FEMENINO" },
      ],
    };
  },
  created() {
    this.pacienteService = new PacienteService();
  },
  computed: {
    edadMesesCliente() {
      if (this.data_paciente.fecha_nacimiento) {
        let fecha_nacimiento = new Date(this.data_paciente.fecha_nacimiento);
        let fecha_actual = new Date();
        let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
        let meses = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
        if (
          meses < 0 ||
          (meses === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
        ) {
          edad--;
          meses += 12;
        }
        return edad + " años y " + meses + " meses";
      }
      return "";
    },
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarPaciente() {
      this.errors = {};
      this.enviado = true;
      if (!this.data_paciente.nombre) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Seleccione un nombre",
          life: 3000,
        });
        this.enviado = false;
        return;
      }
      //editar paciente
      if (this.paciente.id) {
        let paciente_enviar = {
          ...this.data_paciente,
          estado: this.data_paciente.estado.value,
          sexo:
            this.data_paciente.sexo != null
              ? this.data_paciente.sexo.value
              : null,
        };
        this.pacienteService.updatedPaciente(paciente_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_paciente;
        this.data_paciente = {
          ...this.data_paciente,
          estado: this.data_paciente.estado.value,
          sexo:
            this.data_paciente.sexo != null
              ? this.data_paciente.sexo.value
              : null,
        };
        this.pacienteService
          .sendPacienteNuevo(this.data_paciente)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_paciente = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });

              this.$emit("actualizarListado", data.paciente);
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    paciente(val) {
      this.data_paciente = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>  